<template>
  <div>
    <b-row>
      <b-col cols="4">
        <div class="areawrap">
          <div class="d-flex justify-content-between align-items-end mb-4">
            <h2 class="mb-0">
              Personal Information
            </h2>

            <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret right>
              <template #button-content>
                <font-awesome-icon :icon="['fas', 'ellipsis-h']" fixed-width />
              </template>
              <b-dropdown-item href="#" @click.prevent="openInfoModal">
                Edit Information
              </b-dropdown-item>
              <b-dropdown-item href="#" @click.prevent="openChangeOwnerModal">
                Change Owner
              </b-dropdown-item>
              <b-dropdown-item href="#" @click.prevent="$refs.changePasswordModal.show()">
                Change Password
              </b-dropdown-item>
            </b-dropdown>
          </div>

          <b-row class="align-items-center">
            <b-col cols="4" class="mb-5">
              <span class="font-size-sm text-primary">
                Full Name
              </span>
            </b-col>
            <b-col cols="8" class="mb-5">
              <span>
                {{ profileData.owner_name }}
              </span>
            </b-col>
            <b-col cols="4" class="mb-5">
              <span class="font-size-sm text-primary">
                Role
              </span>
            </b-col>
            <b-col cols="8" class="mb-5">
              <span>
                Admin
              </span>
            </b-col>
            <b-col cols="4" class="mb-5">
              <span class="font-size-sm text-primary">
                Email
              </span>
            </b-col>
            <b-col cols="8" class="mb-5">
              <span>
                {{ profileData.owner_email }}
              </span>
            </b-col>
            <b-col cols="4" class="mb-5">
              <span class="font-size-sm text-primary">
                Phone Extension
              </span>
            </b-col>
            <b-col cols="8" class="mb-5">
              <span>
                {{ profileData.owner_extension }}
              </span>
            </b-col>
            <b-col cols="4" class="mb-5">
              <span class="font-size-sm text-primary">
                Status
              </span>
            </b-col>
            <b-col cols="8" class="mb-5">
              <span v-if="profileData.status === 0" class="d-inline-block bg-primary text-white rounded-pill px-2 py-0">
                Pending
              </span>
              <span v-else class="d-inline-block bg-success text-white rounded-pill px-2 py-0">
                Active
              </span>
            </b-col>
          </b-row>
        </div>
        <b-row>
          <div class="areawrap ml-4" style="width:100%">
            <div class="d-flex justify-content-between align-items-center mb-4">
              <h2 class="mb-3">
                Feedback
              </h2>
              <b-link class="text-gray-500" href="#" @click.prevent="onFeedbackCreateClick">
                <font-awesome-icon :icon="['fas', 'plus']" fixed-width />
              </b-link>
            </div>
            <b-row class="align-items-center table-base">
              <b-table borderless :fields="feedbackFields" :items="feedbacks">
                <template #head(created_at)>
                  DATE
                </template>
                <template #head(title)>
                  TITLE
                </template>
                <template #head(category)>
                  CATEGORY
                </template>
                <template #cell(created_at)="data">
                  {{ $dayjs(data.item.created_at).format('YYYY/MM/DD') }}
                </template>
                <template #cell(category)="data">
                  {{ getFeedbackCatetoryText(data.item.category) }}
                </template>
              </b-table>
            </b-row>
          </div>
        </b-row>
      </b-col>

      <b-col cols="4">
        <div class="areawrap">
          <div class="d-flex justify-content-between align-items-end mb-4">
            <h2 class="mb-0">
              Corporate Information
            </h2>
            <b-link class="text-gray-500" href="#" @click.prevent="openCorporateModal">
              <font-awesome-icon :icon="['fas', 'pen']" fixed-width />
            </b-link>
          </div>

          <b-row class="align-items-center">
            <b-col cols="4" class="mb-5">
              <span class="font-size-sm text-primary">
                Company Name
              </span>
            </b-col>
            <b-col cols="8" class="mb-5">
              <span>
                {{ profileData.corporate_name }}
              </span>
            </b-col>
            <b-col cols="4" class="mb-5">
              <span class="font-size-sm text-primary">
                Telephone
              </span>
            </b-col>
            <b-col cols="8" class="mb-5">
              <span>
                {{ profileData.corporate_telphone }}
              </span>
            </b-col>
            <b-col cols="4" class="mb-5">
              <span class="font-size-sm text-primary">
                Address
              </span>
            </b-col>
            <b-col cols="8" class="mb-5">
              <span>
                {{ profileData.corporate_city }},
                {{ profileData.corporate_address }}
              </span>
            </b-col>
            <b-col cols="4" class="mb-5">
              <span class="font-size-sm text-primary">
                Country
              </span>
            </b-col>
            <b-col cols="8" class="mb-5">
              <span>
                {{ country }}
              </span>
            </b-col>
            <b-col cols="4" class="mb-5">
              <span class="font-size-sm text-primary">
                Operation Type
              </span>
            </b-col>
            <b-col cols="8" class="mb-5">
              <span class="d-inline-block bg-info text-white rounded-pill px-2 py-0 mr-2">
                Distributor
              </span>
            </b-col>
            <b-col cols="4" class="mb-5">
              <span class="font-size-sm text-primary">
                Company ID
              </span>
            </b-col>
            <b-col cols="8" class="mb-5">
              <span>
                -
              </span>
            </b-col>
          </b-row>
        </div>
      </b-col>
      <b-col cols="4">
        <div class="areawrap">
          <div class="d-flex justify-content-between align-items-end mb-4">
            <h2 class="mb-0">
              Contact
            </h2>
            <b-link class="text-gray-500" href="#" @click.prevent="openEditContactModal">
              <font-awesome-icon :icon="['fas', 'pen']" fixed-width />
            </b-link>
          </div>

          <b-row class="align-items-center">
            <b-col cols="4" class="mb-5">
              <span class="font-size-sm text-primary">
                Full Name
              </span>
            </b-col>
            <b-col cols="8" class="mb-5">
              <span>
                {{ contactData.contact_name }}
              </span>
            </b-col>
            <b-col cols="4" class="mb-5">
              <span class="font-size-sm text-primary">
                Email
              </span>
            </b-col>
            <b-col cols="8" class="mb-5">
              <span>
                {{ contactData.contact_email }}
              </span>
            </b-col>
            <b-col cols="4" class="mb-5">
              <span class="font-size-sm text-primary">
                Phone Extension
              </span>
            </b-col>
            <b-col cols="8" class="mb-5">
              <span>
                {{ contactData.contact_extension }}
              </span>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>

    <info-modal ref="infoModal" @update="getUserProfileData" />
    <corporate-modal ref="corporateModal" @update="getUserProfileData" />
    <edit-contact-modal ref="editContactModal" @update="getContactData" />
    <change-owner-modal ref="changeOwnerModal" />
    <change-password-modal ref="changePasswordModal" />
    <feedback-create-modal ref="feedbackCreateModal" @update="getFeedbacks" />
  </div>
</template>

<script>
import countryList from '@/common/countryList'
import feedbackCategoryList from '@/common/feedbackCategoryList'
import infoModal from '@/components/profile/infoModal'
import corporateModal from '@/components/profile/corporateModal'
import editContactModal from '@/components/profile/editContactModal'
import changeOwnerModal from '@/components/profile/changeOwnerModal'
import { getUserProfileData } from '@/api/user'
import { getFeedbackList } from '@/api/feedback'
import ChangePasswordModal from '@/components/profile/changePasswordModal'
import feedbackCreateModal from '@/components/profile/feedbackCreateModal'

export default {
  name: 'Profile',
  components: {
    infoModal,
    corporateModal,
    editContactModal,
    changeOwnerModal,
    ChangePasswordModal,
    feedbackCreateModal
  },
  data () {
    return {
      profileData: {},
      contactData: {
        contact_name: 'Catrina Chiu',
        contact_email: 'catrina.c@gmail.com',
        contact_extension: '202'
      },
      feedbacks: []
    }
  },
  computed: {
    country () {
      return countryList.find(country => country.value === this.profileData.corporate_country)?.text
    },
    feedbackFields () {
      return ['created_at', 'title', 'category']
    }
  },
  created () {
    this.getUserProfileData()
    this.getFeedbacks()
  },
  methods: {
    getUserProfileData () {
      getUserProfileData().then(res => {
        this.profileData = res.data.data

        this.contactData.contact_name = this.profileData.contact_name
        this.contactData.contact_email = this.profileData.contact_email
        this.contactData.contact_extension = this.profileData.contact_extension
      })
    },
    getContactData (item) {
      this.contactData = { ...item }
    },
    getFeedbacks () {
      getFeedbackList().then(res => {
        this.feedbacks = res.data.data
      })
    },
    openInfoModal () {
      const data = {
        owner_name: this.profileData.owner_name,
        owner_extension: this.profileData.owner_extension
      }
      this.$refs.infoModal.show(data)
    },
    openCorporateModal () {
      const data = {
        corporate_name: this.profileData.corporate_name,
        corporate_country: this.profileData.corporate_country,
        corporate_city: this.profileData.corporate_city,
        corporate_address: this.profileData.corporate_address,
        corporate_telphone: this.profileData.corporate_telphone
      }
      this.$refs.corporateModal.show(data)
    },
    openChangeOwnerModal () {
      this.$refs.changeOwnerModal.show()
    },
    openEditContactModal () {
      const data = { ...this.contactData }
      this.$refs.editContactModal.show(data)
    },
    onFeedbackCreateClick () {
      this.$refs.feedbackCreateModal.show()
    },
    getFeedbackCatetoryText (item) {
      return feedbackCategoryList.find(p => p.value === item)?.text
    }
  }
}
</script>
