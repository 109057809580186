<template>
  <b-modal
    id="feedback-modal"
    title="Create Feedback"
    cancel-title="CANCEL"
    ok-title="SAVE"
    hide-header-close
    centered
    @ok="submit"
  >
    <div class="px-4">
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Title"
            class="mb-6"
            :state="$v.form.$anyDirty ? !$v.form.name.$anyError : null"
          >
            <b-form-input v-model="form.title" placeholder="Enter Title" />
            <b-form-invalid-feedback :state="$v.form.$anyDirty ? $v.form.title.required : null">
              Title is required
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
              label="Category"
              class="mb-6"
              :state="$v.form.$anyDirty ? !$v.form.corporate_country.$anyError : null"
            >
              <b-form-select
                v-model="form.category"
                :options="feedbackCategoryList"
                :state="$v.form.$anyDirty ? !$v.form.category.$anyError : null"
                @change="onCountryChange"
              >
              </b-form-select>
              <b-form-invalid-feedback :state="$v.form.$anyDirty ? $v.form.category.required : null">
                category is required
              </b-form-invalid-feedback>
            </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Content"
            class="mb-4"
          >
            <b-form-textarea v-model="form.content" />
          </b-form-group>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
import feedbackCategoryList from '@/common/feedbackCategoryList'
import { required } from 'vuelidate/lib/validators'
import { createFeedback } from '@/api/feedback'

export default {
  name: 'EditContactModal',
  data () {
    return {
      form: {
        title: '',
        category: 0,
        content: ''
      },
      success: false
    }
  },
  computed: {
    feedbackCategoryList () {
      return feedbackCategoryList
    }
  },
  validations () {
    return {
      form: {
        title: { required },
        category: { required }
      }
    }
  },
  methods: {
    state (vaild) {
      return this.$v.form.$anyDirty ? vaild : null
    },
    show () {
      this.$bvModal.show('feedback-modal')
      this.$v.form.$reset()
    },
    hide () {
      this.$bvModal.hide('feedback-modal')
      this.form = {
        category: 0,
        title: '',
        content: ''
      }
      this.success = false
    },
    submit (e) {
      e.preventDefault()
      this.$v.form.$touch()
      if (this.$v.form.$anyError) return
      createFeedback(this.form).then(() => {
        this.formSuccess()
      })
    },
    formSuccess () {
      this.success = true
      this.$emit('update')
      this.hide()
    }
  }
}
</script>
